import React, { useEffect, useState } from 'react';

export default function useOnScreen(ref: React.RefObject<any>, rootMargin = '0px') {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible((v) => {
          if (v === false && entry.isIntersecting) {
            return true;
          }

          return v;
        });
      },
      {
        rootMargin,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isVisible;
}
